/* @import "~bootstrap/dist/css/bootstrap.css";
@import "~formiojs/dist/formio.full.min.css"; */

/* .App {
  font-size: 1rem;
  margin: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-group {
  margin-top: 20px;
}

.MuiButtonBase-root {
  position: unset;
}

.MuiList-root {
  display: flex;
  flex-direction: column;
  gap: 10px;
} */

/* .base-Menu-listbox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  padding-inline-start: 0;
} */
@tailwind base;
@tailwind components;
@tailwind utilities;

.raffles-root {
  height: 100%;
}
.custom-style {
  margin: 10px;
}

.pac-container {
  z-index: 2000 !important; /* Ensure it's above the MUI Dialog */
}

.video-container iframe {
  border: 2px solid rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease;
}

.video-container iframe:hover {
  transform: scale(1.03);
}
